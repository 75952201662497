import { client } from '@src/backend/pimcore/clientOld'
import { getUrlParameter } from '@src/helpers/url'

const moment = require('moment')

export default function useShippingOfferClient(query) {
	const decodedQuery = decodeURI(query)
	const apiKey = getUrlParameter(decodedQuery, 'apikey')
	const dateParam = getUrlParameter(decodedQuery, 'parms[startdate]')
	const orderId = getUrlParameter(decodedQuery, 'parms[orderid]')
	const customerId = getUrlParameter(decodedQuery, 'parms[customer]')
	const orderValue = getUrlParameter(decodedQuery, 'parms[ordertotal]')
	const postalCode = getUrlParameter(decodedQuery, 'parms[postcode]')
	const projectId = getUrlParameter(decodedQuery, 'parms[projcust]')
	const storeId = getUrlParameter(decodedQuery, 'parms[company]')

	const momentDate = dateParam ? moment(dateParam, 'DD-MM-YYYY') : moment()
	const date = momentDate.format('YYYY-MM-DD')

	const shippingOfferClient = client(apiKey)

	const getShippingOffers = (fromDate = date) => {
		return shippingOfferClient.get({
			endpoint: '/shippingOffers',
			params: dateParam
				? decodedQuery.replace(dateParam, fromDate)
				: `${decodedQuery}&parms[startdate]=${fromDate}`,
		})
	}

	const createShipmentBooking = (capacityBatchId) => {
		return shippingOfferClient.post({
			endpoint: '/shipmentBooking',
			data: {
				capacityBatchId,
				orderId,
				customerId,
				orderValue,
				postalCode,
				projectId,
				storeId,
			},
		})
	}

	return {
		urlParams: {
			date,
		},
		getShippingOffers,
		createShipmentBooking,
	}
}
