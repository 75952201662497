import React from 'react'
import { deliverySelectionProp } from 'maxboapp-components'
import useShippingOfferTranslations from '@src/hooks/shippingOffer/useShippingOfferTranslations'
import OrderSummary from './OrderSummary'

const ShippingOrderSummary = ({ deliveryDateOption }) => {
	const { additionalInfoTranslations } = useShippingOfferTranslations()
	const {
		deliveryShipping,
		discount: discountLabel,
		moneyPostFix,
		summaryAmount,
	} = additionalInfoTranslations

	const {
		priceInformation: { originalSalesPrice, discount: shippingDiscount, discountedSalesPrice },
	} = deliveryDateOption

	return (
		<OrderSummary
			originalSalesPrice={originalSalesPrice}
			discount={shippingDiscount}
			summary={discountedSalesPrice}
			labels={{
				shippingCost: deliveryShipping,
				discount: discountLabel,
				summary: summaryAmount,
				currency: moneyPostFix,
			}}
		/>
	)
}

ShippingOrderSummary.propTypes = {
	deliveryDateOption: deliverySelectionProp,
}

export default ShippingOrderSummary
