import React from 'react'
import PropTypes from 'prop-types'
import { FeatureTable, FeatureTableItem } from 'maxboapp-components'

const OrderSummary = ({ labels, originalSalesPrice, discount, summary }) => {
	return (
		<FeatureTable receipt>
			<FeatureTableItem
				label={labels.shippingCost}
				value={`${originalSalesPrice} ${labels.currency}`}
			/>
			{discount > 0 && (
				<FeatureTableItem label={labels.discount} value={`-${discount} ${labels.currency}`} />
			)}
			<FeatureTableItem label={labels.summary} value={`${summary} ${labels.currency}`} />
		</FeatureTable>
	)
}

OrderSummary.propTypes = {
	originalSalesPrice: PropTypes.number.isRequired,
	discount: PropTypes.number.isRequired,
	summary: PropTypes.number.isRequired,
	labels: PropTypes.shape({
		shippingCost: PropTypes.string.isRequired,
		discount: PropTypes.string.isRequired,
		summary: PropTypes.string.isRequired,
		currency: PropTypes.string.isRequired,
	}).isRequired,
}

export default OrderSummary
