import useShippingOfferClient from '@src/hooks/shippingOffer/useShippingOfferClient'
import useShippingOfferTranslations from '@src/hooks/shippingOffer/useShippingOfferTranslations'
import useTranslations from '@src/hooks/useTranslations'
import {
	AdditionalInfo,
	Container,
	deliveryServiceLabelHelpers,
	getAllAvailableTransportChoices,
	getAvailableServices,
	getQuickChoicesTransport,
	FormStep,
	DeliveryTime,
	Preloader,
	DeliveryServices,
	useDateFormat,
	useShippingTransportDescriptions,
} from 'maxboapp-components'
import React, { useEffect, useState } from 'react'
import BEMHelper from 'react-bem-helper'
import './CheckoutLayoutProffDAX.scss'
import { ShippingOrderSummary } from '../components'

const moment = require('moment')
const bem = new BEMHelper('checkout-layout-proff-dax')

const ShippingOfferPage = ({ location }) => {
	const { search: query } = location
	const { getShippingOffers, urlParams, createShipmentBooking } = useShippingOfferClient(query)
	const t = useTranslations()

	const { formatDate, getDateWithMinDateToday } = useDateFormat()
	const initialDate = getDateWithMinDateToday(urlParams.date)

	const [activeStep, setActiveStep] = useState(1)
	const [shippingOffersData, setShippingOffersData] = useState([])
	const [fetchingDataError, setFetchingDataError] = useState()
	const [loadingShippingData, setLoadingShippingData] = useState(true)
	const [creatingShipmentBooking, setCreatingShipmentBooking] = useState(false)
	const [shipmentBookingError, setShipmentBookingError] = useState()
	const [deliveryOption, setDeliveryOption] = useState()
	const [deliveryDateOption, setDeliveryDateOption] = useState()

	const [fromDate, setFromDate] = useState(initialDate)
	const [fetchedDates, setFetchedDates] = useState([])
	const [availableServices, setAvailableServices] = useState([])
	const loadingAdditionalData = Boolean(loadingShippingData && shippingOffersData.length)

	const {
		deliveryTranslations,
		deliveryToggleTranslations,
		deliveryScheduleTranslations,
		additionalInfoTranslations,
	} = useShippingOfferTranslations()

	const { transportDescriptions } = useShippingTransportDescriptions(deliveryTranslations)

	const { createLabelHelpers, requiredLabels } = deliveryServiceLabelHelpers

	const deliveryServiceLabels = requiredLabels(
		'erpapp.shipping-offer.collected-delivery-with',
		'erpapp.shipping-offer.collected-delivery-description',
		'erpapp.shipping-offer.service-description',
		'erpapp.shipping-offer.fossil-free-delivery',
		'erpapp.shipping-offer.fossil-free-description',
	)

	const labelHelpers = createLabelHelpers(t, deliveryServiceLabels)

	useEffect(() => {
		async function fetchData() {
			const dateToFetch = moment(fromDate).format('YYYY-MM-DD')

			// Add dates to own "cache" for not fetching data for dates that already have been fetched
			if (fetchedDates.includes(dateToFetch) || moment(dateToFetch).isBefore(initialDate, 'day')) {
				return
			}
			setFetchingDataError(null)
			setLoadingShippingData(true)

			try {
				// Subtracting one day as the api fo shipping offer is filtered on booking-deadline.
				const formattedFromDate = formatDate(fromDate)
				const { data } = await getShippingOffers(formattedFromDate)

				// Only calculate available services for initial fetched data
				if (!availableServices.length) {
					setAvailableServices(getAvailableServices(data))
				}

				setShippingOffersData(shippingOffersData.length ? [...shippingOffersData, ...data] : data)
				setFetchedDates([...fetchedDates, dateToFetch])
			} catch (e) {
				if (e && e.response) {
					if (e.response.data && e.response.data.data) {
						setFetchingDataError(e.response.data.data)
					} else if (e.response.status === 401) {
						setFetchingDataError(t('erpapp.shipping-offer.error.invalid-api-key'))
					}
				}
			}
			setLoadingShippingData(false)
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fromDate]) // Only run effect if from date is changed

	const includeEveningDeliveries =
		process.env.GATSBY_FUTURE_LOGISTIC_EVENING_DELIVERIES_ACTIVE === 'true'
	const quickChoicesTransport = getQuickChoicesTransport(
		shippingOffersData,
		deliveryOption,
		includeEveningDeliveries,
	)
	const allAvailableTransportChoices = getAllAvailableTransportChoices(
		shippingOffersData,
		deliveryOption,
	)

	const sendShipmentBooking = async () => {
		setCreatingShipmentBooking(true)
		setShipmentBookingError(null)
		try {
			await createShipmentBooking(deliveryDateOption.capacityBatchId)
			window.close()
		} catch (e) {
			setShipmentBookingError('erpapp.shipment-booking.error-generic')
		}
		setCreatingShipmentBooking(false)
	}

	return (
		<>
			{(creatingShipmentBooking && (
				<Preloader full status={t('erpapp.shipment-booking.sending-order')} />
			)) || (
				<article {...bem('')}>
					<Container>
						<div {...bem('grid')}>
							<div {...bem('main')}>
								{(loadingShippingData && !loadingAdditionalData && (
									<div {...bem('preload-wrapper')}>
										<Preloader large />
										<span {...bem('preload-wrapper-description')}>
											{t('erpapp.shipping-offer.loading-data')}
										</span>
									</div>
								)) || (
									<>
										{fetchingDataError ? (
											<span>{fetchingDataError}</span>
										) : (
											<>
												<FormStep
													title={t('erpapp.shipping-offer.step1.title')}
													titleSummary={`${t('erpapp.shipping-offer.delivery')}: ${
														deliveryOption ? deliveryOption.serviceInformation.serviceName : ''
													}`}
													step={1}
													activeStep={activeStep}
													setActiveStep={setActiveStep}
												>
													<DeliveryServices
														availableServices={availableServices}
														labeling={labelHelpers}
														onClickHandler={(service) => {
															const isCollectedDelivery =
																service.deliveryInformation.combinedDelivery
															if (isCollectedDelivery) {
																setDeliveryOption(service)
																setDeliveryDateOption(service)
																setActiveStep(3)
															} else {
																setDeliveryOption(service)
																setDeliveryDateOption(undefined)
																setActiveStep(2)
															}
														}}
													/>
												</FormStep>

												<FormStep
													title={t('erpapp.shipping-offer.step2.title')}
													titleSummary={
														deliveryDateOption && deliveryDateOption.transportInformation
															? `${t(
																	'erpapp.shipping-offer.delivery-time',
															  )}: ${transportDescriptions[
																	deliveryDateOption.transportInformation.transportId
															  ].label(deliveryDateOption)}`
															: t('erpapp.shipping-offer.delivery-time')
													}
													step={2}
													activeStep={activeStep}
													setActiveStep={
														deliveryOption && deliveryOption.deliveryInformation.combinedDelivery
															? () => {}
															: setActiveStep
													}
												>
													<DeliveryTime
														deliveryDateOption={deliveryDateOption}
														quickChoicesTransport={quickChoicesTransport}
														transportDescriptions={transportDescriptions}
														allAvailableTransportChoices={allAvailableTransportChoices}
														onClickHandler={(option) => {
															setDeliveryDateOption(option)
															setActiveStep(3)
														}}
														fromDate={fromDate}
														initialDate={initialDate}
														setFromDate={(date) => {
															if (moment(date).isBefore(initialDate)) {
																setFromDate(initialDate)
															} else {
																setFromDate(date)
															}
														}}
														toggleTranslations={deliveryToggleTranslations}
														scheduleTranslations={deliveryScheduleTranslations}
														loadingAdditionalData={loadingAdditionalData}
														includeEveningDeliveries={includeEveningDeliveries}
													/>
												</FormStep>

												<FormStep
													title={t('erpapp.shipping-offer.step3.title')}
													step={3}
													activeStep={activeStep}
													setActiveStep={setActiveStep}
												>
													{deliveryDateOption && (
														<AdditionalInfo
															bookingDeadline={deliveryDateOption.bookingDeadline}
															sendShipmentBooking={sendShipmentBooking}
															error={t(shipmentBookingError)}
															translations={additionalInfoTranslations}
															orderSummary={
																<ShippingOrderSummary deliveryDateOption={deliveryDateOption} />
															}
														/>
													)}
												</FormStep>
											</>
										)}
									</>
								)}
							</div>
						</div>
					</Container>
				</article>
			)}
		</>
	)
}

export default ShippingOfferPage
