import { Client } from 'maxboapp-components/api/abstract-rest-client'

const host = process.env.GATSBY_PIMCORE_ERPAPP_BASE_URL
const version = process.env.GATSBY_PIMCORE_ERPAPP_REST_VERSION
const config = {
	baseURL: `${host}/erpapp/${version}`,
}

export const client = (apiKey) =>
	Client({
		...config,
		headers: {
			'X-Ateles-Maxbo-ErpApp-Key': apiKey,
		},
	})
