import useTranslations from '@src/hooks/useTranslations'
const useShippingOfferTranslations = () => {
	const t = useTranslations()

	const deliveryTranslations = {
		deliveryLatest: t('erpapp.shipping-offer.latest'),
		tomorrow: t('erpapp.shipping-offer.tomorrow'),
		todayAtTime: t('erpapp.shipping-offer.today-at-time'),
		evening: t('erpapp.shipping-offer.evening'),
		withinThreeDays: t('erpapp.shipping-offer.in-three-days'),
		withinSevenDays: t('erpapp.shipping-offer.in-seven-days'),
		withinThreeEvenings: t('erpapp.shipping-offer.in-three-evenings'),
		withinSevenEvenings: t('erpapp.shipping-offer.in-seven-evenings'),
	}
	const deliveryToggleTranslations = {
		displayLess: t('erpapp.shipping-offer.display-less'),
		specificTime: t('erpapp.shipping-offer.choose-specific-date'),
	}
	const deliveryScheduleTranslations = {
		hourlyDelivery: t('erpapp.shipping-offer.hourly-delivery'),
		today: t('erpapp.shipping-offer.today'),
		tomorrow: t('erpapp.shipping-offer.tomorrow'),
		todayAtAnyTime: t('erpapp.shipping-offer.today-at-any-time'),
		todayAtAnyTimeTimeSpan: t('erpapp.shipping-offer.today-at-any-time.time-span'),
		morning: t('erpapp.shipping-offer.morning'),
		morningTimeSpan: t('erpapp.shipping-offer.morning.time-span'),
		morningExpress: t('erpapp.shipping-offer.express-morning'),
		morningExpressTimeSpan: t('erpapp.shipping-offer.express-morning.time-span'),
		afternoon: t('erpapp.shipping-offer.afternoon'),
		afternoonTimeSpan: t('erpapp.shipping-offer.afternoon.time-span'),
		afternoonExpress: t('erpapp.shipping-offer.express-afternoon'),
		afternoonExpressTimeSpan: t('erpapp.shipping-offer.express-afternoon.time-span'),
		evening: t('erpapp.shipping-offer.evening'),
		eveningTimeSpan: t('erpapp.shipping-offer.evening.time-span'),
	}

	const additionalInfoTranslations = {
		addOrder: t('erpapp.shipping-offer.add-order'),
		deliveryShipping: t('erpapp.shipping-offer.delivery-shipping'),
		discount: t('erpapp.shipping-offer.discount'),
		moneyPostFix: t('erpapp.shipping-offer.money-postfix'),
		summaryAmount: t('erpapp.shipping-offer.summary-amount'),
		sendBefore: t('erpapp.shipping-offer.send-order-before'),
	}

	return {
		deliveryTranslations,
		deliveryToggleTranslations,
		deliveryScheduleTranslations,
		additionalInfoTranslations,
	}
}

export default useShippingOfferTranslations
